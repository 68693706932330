// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%278%27 height=%278%27%3E%3Cpath d=%27M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z%27 fill=%27currentColor%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb_icon__KqqUz{
    --bs-breadcrumb-divider: '>';
}
.breadcrumb_svgIcon__SM3dJ {
    --bs-breadcrumb-divider: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}`, "",{"version":3,"sources":["webpack://./src/assets/custom/css/breadcrumb.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;AACA;IACI,gEAAyM;AAC7M","sourcesContent":[".icon{\n    --bs-breadcrumb-divider: '>';\n}\n.svgIcon {\n    --bs-breadcrumb-divider: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `breadcrumb_icon__KqqUz`,
	"svgIcon": `breadcrumb_svgIcon__SM3dJ`
};
export default ___CSS_LOADER_EXPORT___;
