import React, { useState, useEffect } from 'react';
import { Container, ListGroup, ListGroupItem } from 'react-bootstrap';

function SelectModel({ selectedBrand, setSelectedBrand, brands }) {

    const handleModelClick = (model) => {
        setSelected(model);
    }

    const [selectedModel, setSelectedModel] = useState([]);
    const [selected, setSelected] = useState("");

    useEffect(() => {
        setSelectedModel(brands.filter((brand) => brand.name === selectedBrand.name));
    }, [selectedBrand]);

    return (
        <Container style={{ marginBottom: "40px", width: "100%" }}>
            <ListGroup>
                {brands.map((brand) => (
                    <ListGroupItem
                        key={brand.key}
                        onClick={() => handleModelClick(brand)}
                        active={selected === brand}
                        style={{
                            cursor: 'pointer',
                            margin: '10px',
                            // backgroundColor: selected === brand ? '#007bff' : 'white',
                            color: selected === brand ? 'white' : 'black',
                            borderRadius: '5px',
                            border: '1px solid grey'
                        }}
                        className={selected === brand ? "bg-primary" : "bg-soft-secondary"}
                    >
                         <img
                            src={brand.logoUrl}
                            alt={brand.name}
                            style={{
                                width: '40px',
                                height: '40px',
                                marginRight: '10px',
                                borderRadius: '50%',
                            }}
                        />
                        {brand.name}
                    </ListGroupItem>
                ))}
            </ListGroup>
        </Container>
    )
}

export default SelectModel;
