// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-masonry-grid {
  display: flex;
  margin-left: -30px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

.iq-modal > .modal-dialog {
  max-width: 1400px;
}

.file-manager-modal .dzu-dropzone {
  min-height: 500px;
  overflow: hidden;
  border: 1px solid #8a92a6;
}

.dark .file-manager-modal .dzu-dropzone {
  background-color: #151824;
}

.btn-upload input[type=file]::file-selector-button {
  background-color: var(--bs-primary);
  border-radius: 0.25rem;
  box-shadow: none;
  color: white;
  border: 1px solid var(--bs-primary);
}
.btn-upload input[type=file]::file-selector-button:hover {
  background-color: rgba(var(--bs-primary), 0.9);
}

.dark .choices[data-type*=select-one] .choices__input {
  background: #222738;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.dark .is-open .choices__list[aria-expanded] {
  border-color: rgba(255, 255, 255, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/assets/custom/scss/custom.scss"],"names":[],"mappings":"AACA;EAGI,aAAA;EACA,kBAAA;EACA,WAAA;AAAJ;;AAEE;EACE,kBAAA;EACA,4BAAA;AACJ;;AAII;EACI,iBAAA;AADR;;AAOI;EACI,iBAAA;EACA,gBAAA;EACA,yBAAA;AAJR;;AASQ;EACI,yBAAA;AANZ;;AAWI;EACI,mCAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;EACA,mCAAA;AARR;AAWI;EACI,8CAAA;AATR;;AAkBI;EACG,mBAAA;EACA,iDAAA;AAfP;;AAmBA;EACI,sCAAA;AAhBJ","sourcesContent":["//blog-treding\n.my-masonry-grid {\n    display: -webkit-box; \n    display: -ms-flexbox; \n    display: flex;\n    margin-left: -30px; \n    width: auto;\n  }\n  .my-masonry-grid_column {\n    padding-left: 30px; \n    background-clip: padding-box;\n  }\n\n//modals\n.iq-modal{\n    >.modal-dialog {\n        max-width: 1400px;\n    }\n}\n\n//file-manager dropzone\n.file-manager-modal{\n    .dzu-dropzone{\n        min-height:500px;\n        overflow: hidden; \n        border:1px solid #8a92a6;\n    }\n}\n.dark{\n    .file-manager-modal{\n        .dzu-dropzone{\n            background-color:#151824;\n    }\n}\n}\n.btn-upload{\n    input[type=\"file\"]::file-selector-button{\n        background-color: var(--bs-primary);\n        border-radius: 0.25rem;\n        box-shadow: none;\n        color:white;\n        border:1px solid var(--bs-primary);\n    \n    }\n    input[type=\"file\"]::file-selector-button:hover{\n        background-color: rgba(var(--bs-primary), 0.9);\n    }\n}\n\n\n\n//dark choices\n\n.dark .choices[data-type*=select-one]{\n    .choices__input{\n       background: #222738;\n       border-color: rgba(255,255,255,.1) !important;\n    }\n}\n\n.dark .is-open .choices__list[aria-expanded]{\n    border-color: rgba(#ffff,.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
